import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { DiscBlock, AgeDisc, PrivacyPolicy, Terms, About, Contacts, Games, GamesItem, Header, Footer, CookieModal } from './components';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <Header/>
  <CookieModal/>
  <DiscBlock/>
  <AgeDisc/>
    <Routes>
        <Route path="/" element={<App/>}/>
        <Route exact path="/games" element={<Games/>} />
        <Route path="/games/:id" element={<GamesItem/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/contacts" element={<Contacts/>}/>
        <Route path="/terms" element={<Terms/>}/>
        <Route path="/policy" element={<PrivacyPolicy/>}/>
    </Routes>
  <Footer/>
</BrowserRouter>
);

