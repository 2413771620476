import React from 'react';
import styles from "./../styles"
import Container from './Container';

export default function PrivacyPolicy() {
  return (
    <div className='bg-dark text-light'>
    <Container>
    <div className={`${styles.section} pb-10`}>
        <h2 className={`${styles.heading2}`}>Privacy Policy</h2>
        <p>Websites send cookies to visitors&#8217; browsers, where they are stored on their devices. As well as providing information to site owners, they are widely used to make websites work, or to make them more efficient.</p>
<p>here are two main types of cookies: session cookies and persistent cookies. Session cookies are temporary cookies that are deleted when you close your web browser. Persistent cookies remain on your device for a set period of time unless you delete them manually.</p>
<p>What types of information do we collect using cookies?</p>
<p>We use cookies to collect a variety of information about our visitors, including:</p>
<ul>
<li> Information about your device, such as your IP address, web browser type and version, and operating system</li>
<li> Information about your visit, such as the date and time you accessed the site, the pages you visited, and the links you clicked</li>
<li> Any search terms you entered into a search engine before reaching our site</li>
</ul>
<h3>How do we use this information?</h3>
<p>The information we collect using cookies is used for a variety of purposes, including:</p>
<ul>
<li> To understand how our visitors use our website and identify areas where we can improve their experience</li>
<li> To track errors and anomalies on our website so that we can fix them</li>
<li> To test new features and functionality on our website</li>
<li> To target advertising to our visitors both on and off our website</li>
</ul>
<h3>How can I control cookies?</h3>
<p>You can control cookies through your web browser settings. Most web browsers allow you to delete cookies that have been set, block all cookies from being set, or request your permission before a cookie is set. Please note that if you disable cookies, certain features of our website may not work properly.</p>
<p>To learn more about how to control cookies, or if you have any questions about our use of cookies, please contact us.</p>
    </div>
    </Container>
    </div>
  )
}
