import React from 'react';
import {Link} from 'react-router-dom';
import Container from "./Container";
import Button from "./Button";
import styles from "./../styles";
import {heroBg} from "./../assets";

export default function Hero({title}) {
  return (
    <div className="w-full relative" style={{backgroundImage: `linear-gradient(to bottom right, rgb(19 3 45 / 80%), rgb(45 39 39 / 50%)), url(${heroBg})`, backgroundSize: "cover", backgroundPosition: "center center", backgroundRepeat: "no-repeat"}}>
      <Container>
          <div className="py-40 text-center">
            <h2 className="custom-3d-font font-bold font-headingFont tracking-wider italic xs:text-[48px] text-[40px] text-light uppercase">
              {title}</h2>
              <p className={`${styles.paragraph} mb-5 text-light max-w-[500px] mx-auto italic font-extralight`}> Enjoy a seamless gaming experience on any device, anytime, anywhere.</p>
              <p className={` mb-5 text-light text-sm`}>Our games do not involve the use of real money <br/> Online games can be addictive. <br/> Play responsible.</p>

              <Link to="/games"><Button text="Play now!"/></Link>
          </div>
      </Container>
    </div>
  ) 
}
