import React from 'react';
import Hero from "./Hero";
import GamesList from './GamesList';

export default function Games() {
  return (
    <div>
        <Hero title='Our Games'/>
        <GamesList/>
    </div>
  )
}
