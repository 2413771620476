import React from 'react';
import styles from "./../styles";
import Container from './Container';
import CTA from './CTA';
import Features from './Features';
import Hero from './Hero';

export default function About() {
  const brandName = "luxonitefit";

  return (
    <div className='bg-dark text-light'>
      <Hero title="About Us"/>
      <Container>
        <div className='text-blue pt-8 pb-0 md:pt-16 md:pb-10'>
          <p className={`${styles.paragraph} mb-10`}>
            <span className="website">{brandName}</span> started as a small coding project among friends who wanted to create a fun and engaging platform for free online pokies. Over time, this project grew into something much larger, and now <span className="website">{brandName}</span> is your ultimate destination for free online pokies, offering the thrill of casino games without the risk of playing with real money.
          </p>
          <p className={`${styles.paragraph} mb-10`}>
            Our pokies are completely free and designed for pure entertainment. No real money can be won or lost here. With many years of combined experience in playing and developing online pokies, our team has crafted some of the most exciting and engaging games available.
          </p>
          <p className={`${styles.paragraph} mb-10`}>
            You can enjoy <span className="website">{brandName}</span> on any device, whether it be mobile, desktop, or tablet, allowing you to play your favorite games anytime, anywhere. We are dedicated to providing the best online pokies experience and constantly seek to improve our platform. If you have any suggestions or feedback, please reach out to us through our online contact form. Our dedicated team is available 24/7 to assist you with any queries.
          </p>
        </div>
      </Container>
      <CTA/>
      <Container>
        <Features/>
      </Container>
    </div>
  );
}
