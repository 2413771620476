import React from "react";
import { Link } from "react-router-dom";
import styles from "../styles";
import Container from "./Container";
import { games } from "../data/games";

export default function GameList() {
  return (
    <div className="pt-10 pb-20 bg-dark text-light">
      <Container>
        <h2
          className={`${styles.heading2} text-center uppercase font-extrabold`}
        >
          Our games
        </h2>

        <ul className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-3 gap-2 sm:gap-12">
          {games.map((item) => (
            <li
              key={item.id}
              className="bg-dark rounded-lg overflow-hidden shadow-custom relative hover:scale-95 transition duration-300 ease-in-out"
            >
              <Link to={`/games/${item.id}`}>
                <div className="">
                  <h2 className="font-bold text-light font-headingFont sm:text-xl mb-2 p-2 text-center absolute bg-dark bg-opacity-75 w-full">
                    {item.title}
                  </h2>
                </div>
                <img
                  src={item.image}
                  alt={item.id}
                  className="w-full h-50 object-cover"
                />
                <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition duration-300 ease-in-out">
                  <div className="bg-dark opacity-90 rounded-lg px-6 py-4 w-full h-full flex items-center justify-center">
                    <span className="text-light text-[24px] md:text-[36px] font-bold font-headingFont uppercase">
                      Play Now!
                    </span>
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </Container>
    </div>
  );
}
