import React from 'react';

function Button(props) {
  return (
    <button className="bg-transparent text-light border-[.1px] border-light font-bold py-3 px-8 rounded-md transition duration-300 cubic-bezier(.4, 0, 0.2, 1); hover:bg-dark">
      {props.text}
    </button>
  );
}

export default Button;