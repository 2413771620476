import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Container from "./Container";
import { games } from "../data/games";
import styles from "../styles";
import { FaSpinner } from "react-icons/fa";

export default function GamesItem() {
  const { id } = useParams();
  const [game, setGame] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchedGame = games.find((game) => game.id === id);
    setGame(fetchedGame);
  }, [id]);

  useEffect(() => {
    if (game) {
      setLoading(false); // Set loading to false after fetching game data
    }
  }, [game]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FaSpinner className="animate-spin text-4xl" />
      </div>
    );
  }

  return (
    <div className="bg-dark">
      <Container>
        <div className="py-10">
          <h2
            className={`${styles.heading2} font-headingFont italic text-center uppercase text-secondaryAccent custom-3d-font`}
          >
            Social {game.title}
          </h2>
          <div className="pt-[40%] relative mx-auto max-w-3xl">
            <iframe
              src={game.url}
              allowFullScreen
              autoPlay
              title={game.title}
              className="mx-auto h-full w-full absolute left-0 top-0"
            ></iframe>
          </div>
        </div>
      </Container>
    </div>
  );
}