import React, { useState, useEffect } from 'react';
import {Link} from "react-router-dom";

export default function CookieModal() {
  const [showCookieModal, setShowCookieModal] = useState(false);
  const [cookieAccepted, setCookieAccepted] = useState(false);

  useEffect(() => {
    // Check if the user has already accepted cookies
    const cookieAccepted = localStorage.getItem('cookieAccepted');
    if (!cookieAccepted) {
      setShowCookieModal(true);
    } else {
      setCookieAccepted(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    // Set a cookie to remember that the user has accepted cookies
    localStorage.setItem('cookieAccepted', true);
    setCookieAccepted(true);
    setShowCookieModal(false);
  };

  return (
    <div>
      {showCookieModal && !cookieAccepted && (
        <div className="fixed bottom-0 left-0 right-0 rounded-lg bg-dark bg-opacity-70 text-light m-5 mb-2 p-4 shadow-md text-center z-10">
          <p className="text-small mb-4">This site uses cookies to provide you with a better browsing experience. By continuing to use this site, you consent to our use of cookies.</p>
          <Link to="policy" className="text-mainAccent ml-2 underline">Learn more</Link>
          <button onClick={handleAcceptCookies} className="bg-transparent border-2 border-light hover:bg-light hover:text-secondaryAccent text-white rounded-md px-4 py-1 ml-4 transition duration-300 cubic-bezier(.4, 0, 0.2, 1);">Accept</button>
          <div className="close-btn border-r-10 absolute top-[50%] right-[5%] translate-y-[-50%]">
            <button onClick={() => setShowCookieModal(false)} className="text-mainAccent rounded-md px-4 py-1 ml-4 transition hover:scale-125 duration-300 cubic-bezier(.4, 0, 0.2, 1);">
              <span className="sr-only">Close menu</span>
                <svg className="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" ariaHidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}