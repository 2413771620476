import React, { useState } from 'react';
import { CheckCircleIcon } from '@heroicons/react/20/solid'


function Form() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});

  const [showModal, setShowModal] = useState(false);


  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      console.log(`Name: ${name}\nEmail: ${email}\nMessage: ${message}`);
      setShowModal(true);
      setName('');
      setEmail('');
      setMessage('');
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!name) {
      errors.name = 'Name is required';
      isValid = false;
    }

    if (!email) {
      errors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email is invalid';
      isValid = false;
    }

    if (!message) {
      errors.message = 'Message is required';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  return (
    <>
    <form className="max-w-lg mx-auto p-6 my-10 bg-light rounded-lg shadow-xl">
      <div className="mb-4">
        <label className="block text-dark-700 font-bold mb-2" htmlFor="name">
          Name:
        </label>
        <input
          className={`appearance-none border bg-light border-dark rounded w-full py-2 px-3 text-dark-700 leading-tight focus:outline-none focus:shadow-outline ${errors.name && 'border-red'}`}
          id="name"
          type="text"
          placeholder="Your name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        {errors.name && <p className="text-red text-xs italic mt-2 text-center">{errors.name}</p>}
      </div>
      <div className="mb-4">
        <label className="block text-dark-700 font-bold mb-2" htmlFor="email">
          Email:
        </label>
        <input
          className={`appearance-none border bg-light border-dark rounded w-full py-2 px-3 text-dark-700 leading-tight focus:outline-none focus:shadow-outline ${errors.email && 'border-red'}`}
          id="email"
          type="email"
          placeholder="Your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {errors.email && <p className="text-red text-xs italic mt-2 text-center">{errors.email}</p>}
      </div>
      <div className="mb-4">
        <label className="block text-dark-700 font-bold mb-2" htmlFor="message">
          Message:
        </label>
        <textarea
          className={`appearance-none border bg-light border-dark rounded w-full py-2 px-3 text-dark-700 leading-tight focus:outline-none focus:shadow-outline ${errors.message && 'border-red'}`}
          id="message"
          placeholder="Your message"
          rows="4"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        {errors.message && <p className="text-red text-xs italic mt-2 text-center">{errors.message}</p>}
      </div>
      <div className="flex justify-center">
        <button
          className="bg-secondaryAccent hover:bg-red text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 cubic-bezier(.4, 0, 0.2, 1);"
          type="submit"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </form>

{showModal && (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white rounded-md p-8 text-center">
      <CheckCircleIcon className="text-green mx-auto w-12 h-12" />
        <h2 className="font-bold text-lg mb-4">Success!</h2>
        <p>Your form has been submitted.</p>
        <button onClick={() => setShowModal(false)} className="bg-secondaryAccent hover:bg-secondaryAccent text-white rounded-md px-4 py-2 mt-4 mx-auto transition duration-300 cubic-bezier(.4, 0, 0.2, 1);">Close</button>
      </div>
    </div>
  )}
  </>
  );
}

export default Form;