import React from "react";
import {GamesList, Hero, CTA, Features, DiscBlock} from "./components";

function App() {
  const website = 'luxonitefit';
  return (
    <div className="App">
        <Hero title={website}/>
        <GamesList/>
        <CTA/>
        <Features/>
    </div>
  );
}
 
export default App;
