import React from 'react';

export default function DiscBlock() {
  return (
    <div className="no-money-disclaimer">
      <div className="nomoney-icon">
      <img src="https://img.icons8.com/?id=76MTnAV3b8gc&format=png" alt="" />
           </div>
      <div className="message">18+. No real money</div>
    </div>
  );
}
                                                       