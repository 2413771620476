import React from 'react';
import Form from './Form';
import Container  from './Container';
import Hero from './Hero';

export default function Contacts() {
  return (
    <div className='bg-dark'>
    <Hero title="Contact Us"/>
    <Container>
      <h3 className='text-center text-light pt-10 font-poppins italic font-normal text-[28px] md:text-[38px] w-full'>Leave us a message</h3>
        <Form/>
    </Container>
    </div>
  )
}
