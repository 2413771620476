import React, { useState, useEffect } from "react";

const AgeDisc = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const hasVisited = localStorage.getItem("hasVisited");
    if (!hasVisited) {
      setOpen(true);
      localStorage.setItem("hasVisited", "true");
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {open && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-dark bg-opacity-75">
          <div className="bg-white rounded-lg p-6 max-w-lg mx-auto text-center">
            <h2 className="text-2xl font-bold mb-4">Are you over 18 years old?</h2>
            <p className="text-lg mb-6">
              This website is intended for persons over 18 years of age
              <br /> and is for entertainment purposes only.
            </p>
            <div className="flex justify-center space-x-4">
              <button
                className="bg-secondaryAccent text-white px-4 py-2 rounded hover:bg-dark"
                onClick={handleClose}
              >
                Yes
              </button>
              <button
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
                onClick={handleClose}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AgeDisc;
