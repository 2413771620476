import React from 'react';
import {Link} from "react-router-dom";
import { ctaBg } from '../assets';

function CTA() {
  return (
    <div className="bg-dark justify-center items-center px-10 md:px-20">
      <div className="sm:pt-10 pb-10 sm:pb-0 text-center">
      <h2 className="text-mainAccent text-2xl font-mainFont mb-5 max-w-xl mx-auto">This website intended just for fun and not involve the use of real money.</h2>
      <Link to="/games"><button className="bg-mainAccent text-thirdAccent font-bold rounded-lg py-3 px-6 transition-colors duration-300">Play now!</button></Link>
      </div>
      <img src={ctaBg} className="max-w-2xl mx-auto rounded-md mt-5 shadow-xl" alt="" />
    </div>
  );
}

export default CTA;